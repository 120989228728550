import React from 'react';
import './css/styles.css';

const TwoYears = () => {
  //   const [isDesktop, setIsDesktop] = useState(false);
  //   const [isMobile, setIsMobile] = useState(false);

  //   useEffect(() => {
  //     if (window.innerWidth > 769) {
  //       setIsDesktop(true);
  //       setIsMobile(false);
  //     } else {
  //       setIsMobile(true);
  //       setIsDesktop(false);
  //     }
  //   }, []);

  return (
    <div id="TwoYearsProgra">
      <div id="M_E" className="programContainer">
        <div class="programItem lefter">
          <div class="text">Simple Models</div>
        </div>
        <div class="programItem left">
          <div class="text">Measuring</div>
        </div>
        <div class="programItem center">
          <div class="explainer">
            <span>Mechanical Engineering</span>
          </div>
          <div class="text">Mechanics</div>
        </div>
        <div class="programItem right">
          <div class="text">Calculating</div>
        </div>
        <div class="programItem righter">
          <div class="text">3D Design</div>
        </div>
      </div>

      <div id="S_E" className="programContainer">
        <div class="programItem left">
          <div class="text">Measuring</div>
        </div>
        <div class="programItem center">
          <div class="explainer">
            <span>Web Design</span>
          </div>
          <div class="text">Web-Design</div>
        </div>
        <div class="programItem right">
          <div class="text">Calculating</div>
        </div>
        <div class="programItem righter">
          <div class="text">3D Design</div>
        </div>
      </div>

      <div id="E_E" className="programContainer">
        <div class="programItem lefter">
          <div class="text">Simple Models</div>
        </div>
        <div class="programItem left">
          <div class="text">Measuring</div>
        </div>
        <div class="programItem center">
          <div class="explainer">
            <span>Electronic Engineering</span>
          </div>
          <div class="text">Electronics</div>
        </div>
        <div class="programItem right">
          <div class="text">Calculating</div>
        </div>
        <div class="programItem righter">
          <div class="text">3D Design</div>
        </div>
      </div>

      <div id="IoT" className="programContainer">
        <div class="programItem left">
          <div class="text">Measuring</div>
        </div>
        <div class="programItem center">
          <div class="explainer">
            <span>
              Iinternet <br /> of Things
            </span>
          </div>
          <div class="text">IoT</div>
        </div>
        <div class="programItem right">
          <div class="text">Calculating</div>
        </div>
      </div>
    </div>
  );
};

export default TwoYears;
