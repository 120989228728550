import React from "react";
import { Helmet } from "react-helmet";
import App from "../components/App";
import { headData } from "../mock/data";
import "../style/bootstrap.min.css";
import "../style/main.scss";

export default function Main() {
  const { title, lang, description, favicon } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <title>{title || "Portfolio"}</title>
        <html lang={lang || "en"} />
        <meta name="description" content={description || "Simplefolio"} />
      </Helmet>
      <App />
    </>
  );
}
